<template>
    <create-view v-on:create-view="addViewToProject" v-on:cancel="cancelCreate" />
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import createView from '@/views/views/create';

export default {
    name: 'project-view-create',
    data: () => ({}),
    props: {},
    components: {
        createView,
    },
    mounted() {},
    computed: {
        ...mapGetters(['project']),
    },
    methods: {
        ...mapActions(['addProjectView']),
        cancelCreate() {
            this.$router.go(-1);
        },
        addViewToProject(view) {
            const payload = {
                project: this.project.id,
                view: view,
            };

            this.addProjectView(payload);
            this.$router.go(-1);
        },
    },
};
</script>
